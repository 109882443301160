import React from 'react'

function FullWidthBar() {
  return (
    <p style={{ visibility: 'hidden', height: '0' }}>
      ---------------------------------------------------------------------------------------------
                This paragraph is used for fulling the width of the flex item container
      ---------------------------------------------------------------------------------------------
		</p>
  )
}

export default FullWidthBar